import React from 'react';
import styled from 'styled-components';

import YellowShape from '../assets/svg/bg-shapes/yellow.svg';
import RedShape from '../assets/svg/bg-shapes/red.svg';
import BlueShape from '../assets/svg/bg-shapes/blue.svg';
import GreenShape from '../assets/svg/bg-shapes/green.svg';

const Background: React.FC = () => (
  <Container>
    <CircleBg
      color="#ef4141"
      t="50%, -50%"
      top="-10%"
      right="-5%"
      shapeOffset={{ x: [-30, -20], y: [20, 60], scale: 0.4 }}
    >
      <RedShape />
    </CircleBg>
    <CircleBg
      color="#fcde56"
      t="-50%, -50%"
      top="10%"
      left="10%"
      shapeOffset={{ x: [10, 10], y: [10, 10], scale: 0.9 }}
    >
      <YellowShape />
    </CircleBg>
    <CircleBg
      color="#1871f3"
      t="-50%, 50%"
      bottom="-20%"
      left="-10%"
      shapeOffset={{ x: [50, 20], y: [-30, -75], scale: 0.8 }}
    >
      <BlueShape />
    </CircleBg>
    <CircleBg
      color="#a7f80a"
      t="50%, 50%"
      bottom="-10%"
      right="-10%"
      shapeOffset={{ x: [-50, -30], y: [-15, -25], scale: 1 }}
    >
      <GreenShape />
    </CircleBg>
  </Container>
);

const Container = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: -1;
`;

interface CircleBgProps {
  color: string;
  t: string;
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;

  shapeOffset: { x: number[]; y: number[]; scale?: number };
}

const CircleBg = styled.div<CircleBgProps>`
  position: absolute;

  width: 200vw;
  height: 200vh;

  transform: translate(${p => p.t});

  left: ${p => p.left || 'auto'};
  right: ${p => p.right || 'auto'};
  top: ${p => p.top || 'auto'};
  bottom: ${p => p.bottom || 'auto'};

  background: radial-gradient(50% 50% at center, ${p => p.color} 0%, transparent);
  opacity: 0.4;

  svg {
    position: relative;
    top: 50%;
    left: 50%;

    width: 60vw;
    height: 60vw;

    opacity: 0.4;

    transform: translate(${p => p.shapeOffset.x[0] - 50}%, ${p => p.shapeOffset.y[0] - 50}%)
      ${p => p.shapeOffset.scale && `scale(${p.shapeOffset.scale})`};

    @media (max-width: 600px) {
      width: 120vw;
      height: 120vw;

      transform: translate(${p => p.shapeOffset.x[1] - 50}%, ${p => p.shapeOffset.y[1] - 50}%)
        ${p => p.shapeOffset.scale && `scale(${p.shapeOffset.scale})`};
    }
  }
`;

export default Background;

import React from 'react';
import styled from 'styled-components';
import Background from './Background';
import Nav from './Nav';
import GlobalStyles from './styles/GlobalStyles';

const Layout: React.FC = ({ children }) => (
  <>
    <GlobalStyles />
    <Background />

    <Container>
      <Nav />
      {children}
    </Container>
  </>
);

const Container = styled.div`
  margin: 0 var(--site-margin);

  display: grid;
  grid-template-rows: var(--nav-size) minmax(calc(100vh - var(--nav-size)), auto);
`;

export default Layout;

import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import IconFB from '../assets/svg/fb.svg';
import IconIG from '../assets/svg/insta.svg';

const Nav: React.FC = () => (
  <Container>
    <SiteTitle to="/">
      <h1>Oratorij Želimlje</h1>
    </SiteTitle>
    <LinksContainer>
      <Link to="/galerija">galerija</Link>
      <Link to="/pravilnik">pravilnik</Link>
      <Link to="/kontakt">kontakt</Link>
    </LinksContainer>
    <SocialConstainer>
      <a href="https://www.facebook.com/oratorijzelimlje">
        <IconFB />
      </a>
      <a href="https://www.instagram.com/oratorijzelimlje">
        <IconIG />
      </a>
    </SocialConstainer>
  </Container>
);

const Container = styled.div`
  display: grid;
  place-items: center;

  grid-template-columns: auto auto auto;
  grid-template-rows: 1fr;
  gap: 0.4rem;
  grid-template-areas: 'title links social';

  @media (max-width: 800px) {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    gap: 0;
    grid-template-areas:
      'title social'
      'links links';
  }
`;

const Link = styled(GatsbyLink).attrs({ activeClassName: 'active' })`
  position: relative;
  font-size: 1.1rem;
  margin: 0 0.8rem;
  text-decoration: none;

  @media (max-width: 800px) {
    font-size: 0.9rem;
  }

  @media (max-width: 350px) {
    font-size: 0.85rem;
  }

  &:after {
    transition: width 0.3s ease-out;
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0%;
    height: 6px;
    background: var(--zelena);
    opacity: 0.6;
    z-index: -1;
  }

  &:hover,
  &.active {
    &:after {
      width: 100%;
    }
  }
`;

const SiteTitle = styled(GatsbyLink)`
  text-decoration: none;
  grid-area: title;
  justify-self: start;

  h1 {
    color: var(--crna);
    font-size: 2.25rem;
    @media (max-width: 1000px) {
      font-size: 1.8rem;
    }
    @media (max-width: 600px) {
      font-size: 1.4rem;
    }
    @media (max-width: 350px) {
      font-size: 1.1rem;
    }
  }

  @media (max-width: 800px) {
    align-self: end;
  }
`;

const LinksContainer = styled.div`
  grid-area: links;
  padding: 0.4rem 1rem;

  @media (max-width: 800px) {
    border: 1px solid rgba(0, 0, 0, 0.08);
    border-right: 0;
    border-left: 0;
  }

  @media (max-width: 350px) {
    padding: 0.2rem;
  }
`;

const SocialConstainer = styled.div`
  grid-area: social;
  justify-self: end;

  @media (max-width: 800px) {
    align-self: end;
  }

  svg {
    display: inline;
    margin-left: 1.6rem;

    height: 1.4rem;
    width: 1.4rem;

    @media (max-width: 800px) {
      margin-left: 1rem;
      height: 1.2rem;
      width: 1.2rem;
    }
  }
`;

export default Nav;

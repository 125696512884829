import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --rumena: #F0C814;
    --rdeca: #A51414;
    --modra: #0046AD;
    --zelena: #7AB800;
    --crna: #1c1f31;

    --rdeca-err: #d41e1e;
    --zelena-light: #d3e6b1;
    --modra-light: #82ace8;

    --site-margin: 6rem;
    --nav-size: 6.8rem;

    @media (max-width: 1000px){
      --site-margin: 2rem;
    }

    @media (max-width: 400px){
      --site-margin: 0.8rem;
      --nav-size: 6rem;
    }
  }

  * {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    color: var(--crna);
  }

  // number input arrows
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // number input arrows
  input[type=number] {
    -moz-appearance: textfield;
  }

  // Scrollbar 
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--crna) #f5de76;
  }
  body::-webkit-scrollbar-track {
    background: #f5de76;
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--crna);
    border-radius: 6px;
    border: 3px solid #f5de76;
  }
`;

export default GlobalStyles;
